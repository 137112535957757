.proxy-card {
  padding: 10px;
  margin: 20px 10px 0;
  border-top: 1px solid rgba(0, 0, 0, .15);
  border-left: 1px solid rgba(0, 0, 0, .15);
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .4);

  @media (max-width: $screen-sm) {
    width: 100%;
    max-width: 100%;
  }
}