.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: $color-primary-4;
  color: $color-primary-1;
  font-weight: 400;
  box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.4);
  //padding: 0 0 20px 0;
  border-radius: 0;
  border: 0;
  @include transition;

  &.shrink {
    padding: 0;
  }

  #lighter-bar {
    //width: 100%;
    padding: 0 20px 40px;
    background-color: $color-primary-0;
    @include transition;

    &.shrink {
      padding: 0;
    }
  }

  img {
    max-height: 40px;
    @include transition;

    &.shrink {
      max-height: 30px;
      margin: 8px 0;
    }
  }

  &-items {
    display: inline-block;

    a {
      text-transform: uppercase;
      font-size: 20px;
      display: inline-block;
      padding: 15px 15px;
      color: $color-primary-2;

      &.active {
        text-decoration: none;
        color: $color-primary-4;
      }

      &:hover, &:active, &:focus {
        text-decoration: none;
        color: $color-primary-1;
      }
    }
  }

  .Nav {
    display: inline-block;
    vertical-align: bottom;
    font-size: 24px;
    font-weight: 300;

    @media (max-width: 1024px) {
      font-size: 20px;
    }

    ul {
      margin-bottom: 0;

      li {
        padding: 16px 20px;
        text-transform: uppercase;
        @include transition;

        &.shrink {
          padding: 0 20px;
        }

        a {
          color: $color-primary-2;
          @include transition;

          &:hover {
            text-decoration: none;
            color: $color-primary-1;
          }

          &.active {
            text-decoration: none;
            color: $color-white;
          }
        }
      }
    }
  }
}

.subnav {
  position: fixed;
  top: 69px;
  left: 0;
  right: 0;
  z-index: 999;
  background: $navbar-default-bg;
  box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.4);
  padding: 0 0;
  border-radius: 0;
  @include transition;

  &.shrink {
    padding: 0;
    top: 32px;
  }

  &-title {
    display: inline-block;
    color: $color-primary-4;
    padding: 6px 15px;
    @include transition;

    &.shrink {
      font-size: 16px;
      padding: 0 15px;
    }
  }

  &-items {
    display: inline-block;

    a {
      text-transform: uppercase;
      font-size: 16px;
      display: inline-block;
      padding: 10px 15px;
      color: $color-primary-0;

      &.active {
        text-decoration: underline;
        color: $color-primary-4;
      }

      &.shrink {
        padding: 0 15px;
      }

      &:hover, &:active, &:focus {
        color: $color-primary-3;
      }
    }
  }
}

.navbar-sub {
  top: 112px;
  background-color: $navbar-default-bg;
  box-shadow: 0 3px 4px 1px rgba(0, 0, 0, 0.4);
}

.brand {
  height: 50px;
}

.brand-small {
  height: 30px;
  width: auto;
}

.Slidemenu {
  position: fixed;
  top: 40px;
  left: 0;
  right: 0;
  @include transition;
  background: white;
  padding-top: 50px;
  border-bottom: 1px solid #aaa;
  z-index: 800;
  transform: translate3d(0, -102%, 0);
  -webkit-transform: translate3d(0, -102%, 0);

  li { padding: 5px 20px; }

  & .active {
    text-decoration: underline;
    color: $navbar-secondary;
  }

  &.open {
    transform: none;
    -webkit-transform: none;
  }
}