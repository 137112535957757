h1, h2, h3, h4, h5, h6 {
  text-align: center;
  color: $color-primary-4;
  padding-bottom: 12px;
  font-weight: 500;
  border-bottom: 1px solid $color-primary-4;

  &.no-border {
    border: none;
  }
}

.onParallax {
  font-size: 42px;
  width: 100%;
  text-align: center;
  color: $color-primary-4;
  text-shadow: 0 0 3px rgba(255, 255, 255, .8);
}