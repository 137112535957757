
// Body
$body-bg: #e7e8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger:  #bf5329;

// Typography
$font-family-sans-serif: "Roboto", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;
$navbar-primary: #6495ed;
$navbar-secondary: #434fba;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

// colors

$color-bg-white: #fff;
$color-white: #fff;

$color-primary-0: #6494ed;
$color-primary-1: #bdd3fb;
$color-primary-2: #8bb0f4;
$color-primary-3: #427ae3;
$color-primary-4: #1f5ed2;

$rotation: -23deg;

// size

$grid-gutter-width: 20px;
$container-large-desktop: (1200px + $grid-gutter-width);

.color-primary-4 {
  color: $color-primary-4;
}