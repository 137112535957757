footer {
  background-color: $color-primary-4;
  color: $color-white;
  padding: 10px 0;
  margin-top: 3px;
  box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, .4);
  z-index: 1;

  & .inner {
    width: 1200px;
    margin: 0 auto;
  }

  & h5 {
    color: $color-primary-1;
    border-bottom: 1px solid $color-primary-1;
    font-size: 16px;
    line-height: 1;
    padding-bottom: 2px;
    margin-bottom: 0;
  }

  & ul {
    list-style-type: none;
    padding: 0;
    font-size: 16px;
    text-align: left;
    width: 120px;
    margin: 0 auto;

    & a {
      color: $color-white;

      &:hover {
        color: $color-white;
      }
    }
  }

  .copyright {
    font-size: 16px;
    color: $color-white;
  }
}