.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-center {
  justify-content: center;
}

.flex-center-vertically {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.flex-grow {
  flex-grow: 1;
  margin: 0 10px;
}

.flex-down {
  align-items: flex-end;
}