.carousel {
  position: relative;
  overflow: hidden;
  margin: 30px 0;
  height: 280px;

  & img {
    height: 280px;
    width: auto;
  }

  & .btn-prev, .btn-next {
    position: absolute;
    height: 100%;
    top: 0;
    z-index: 1;
    padding: 0 20px;
    margin: 0;
    background-color: rgba(139, 176, 224, .6);
    border: none;
    color: $color-white;
    @include transition;

    &:hover {
      @include transition;
      background-color: rgba(139, 176, 224, 9);
    }
  }

  & .btn-prev {
    left: 0;
  }

  & .btn-next {
    right: 0;
  }
}