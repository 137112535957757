.infobox {
  position: absolute;
  bottom: 20px;
  width: 100%;
  height: 300px;
}

.vacation-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, .5);
  display: flex;
  justify-content: center;
  cursor: pointer;

  .text-layer {
    font-size: 24px;
    font-weight: bolder;
    letter-spacing: 8px;
    color: $color-primary-4;
    padding: 80px 0;
    -webkit-transform: rotate($rotation);
    -moz-transform: rotate($rotation);
    -ms-transform: rotate($rotation);
    -o-transform: rotate($rotation);
  }
}

.no-events {
  pointer-events: none;
}

.overlay-box {
  display: inline-block;
  background-color: $color-bg-white;
  width: 420px;
  position: absolute;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, .15);
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .4);
  font-size: 16px;
  bottom: 0;

  & a {
    color: $color-primary-3;
    text-decoration: none;

    &:hover {
      color: $color-primary-4;
      text-decoration: underline;
    }
  }

  & h2 {
    font-size: 22px;
    margin-top: 0;
  }

  & ul {
    padding: 0;
    list-style-type: none;
    margin-bottom: 0;

    & li {

      & .weekday {
        width: 120px;
        display: inline-block;
      }

      & .hours {
        display: inline-block;
      }
    }
  }
}

.consultation {
  left: 20px;
}

.contact {
  right: 20px;
  font-size: 16px;

  .mail {
    margin-left: 127px;
  }
}