#hero {
  width: 100%;
  height: calc(100vh - 114px);
  text-align: left;
  position: relative;
  border: none;

  & .hero-image {
    background-image: url('/img/system/start_header.jpg');
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  & .hero-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;

    & .hero-container {
      position: relative;
      height: 100%;
      top: 0;
      z-index: 10;

      & .claim {
        position: absolute;
        top: 0;
        left: 0;
        margin-top: -20px;
        color: $color-white;
        width: 100%;
        padding: 20px;

        & .claim-bottom {

          & h1 {
            font-size: 42px;
            line-height: 1.1;
            color: $color-primary-4;
            border: none;
            text-align: left;
            font-weight: normal;
          }
        }

      }
    }
  }
}