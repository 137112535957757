html, body {
  height: 100%;
}

body {
  background-color: $color-white;
  font-size: 20px;
  font-weight: 300;
  @media (max-width: $screen-xs) {
    font-size: 16px;
  }
}

main {
  position: relative;
  min-height: calc(100% - 134px);
}

.menu-margin-top {
  margin-top: 114px;

  @media (max-width: $screen-sm) {
    margin-top: 67px;
  }

  &-mobile {
    margin-top: 67px;
  }
}

.ScrollUp {
  position: fixed;
  right: 20px;
  bottom: 20px;
  color: $color-primary-1;
  z-index: 499;
  border-radius: 3px;
  display: none;
  opacity: 0.75;
  transition: 0.5s;

  &:hover {
    transition: 0.5s;
    opacity: 1;
    color: $color-primary-0;
  }
}

.dl-small {

  margin-bottom: 0;

  & dt {
    width: 80px;
  }

  & dd {
    margin-left: 90px;
  }

}

.dl-horizontal.custom {
  padding: 10px;

  & dd {
    text-align: right;
    margin-left: 0;

    @media (max-width: $screen-xs) {
      text-align: center;
    }
  }

  & dt {
    width: inherit;
  }
}

.font-blue {
  color: $color-primary-4;
}

.font-white {
  color: $color-white;
}

.font-weight {

  &--300 {
    font-weight: 300 !important;
  }
}

.close {
  top: 5px;
}

#times > tbody > tr > td {
  border-top: none;
}

ul {
  @media (max-width: $screen-xs) {
    padding-left: 0;
  }
}