.logo {
  font-size: 24px;
  color: $color-primary-1;
  text-transform: uppercase;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 1px solid $color-primary-1;
  line-height: 1.1;
  margin: 20px 0;
  @include transition;

  &.shrink {
    font-size: 20px;
    color: $color-white;
    border-bottom: 1px solid $color-white;
    margin: 5px 0;

    &:hover {
      color: $color-white;
      text-decoration: none;
      border-bottom: 1px solid $color-white;
    }
  }

  &:hover {
    color: $color-white;
    text-decoration: none;
    border-bottom: 1px solid $color-white;
  }
}

.strong {
  font-weight: 700;
}

.thin {
  font-weight: 300;
}
