#kontakt {

  input[type="radio"] {
    margin-top: 6px;
  }

  @media (max-width: $screen-sm) {
    background-color: #669FE2;
    background: url('/img/system/contact-slide-01.jpg');
  }
}

#type-selector {
  color: #efefef;
  @media (max-width: $screen-sm) {
    color: #efefef;
  }
}