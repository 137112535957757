.portrait {
  height: 180px;
  width: auto;
  margin: 0 auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
  0 3px 6px rgba(0, 0, 0, 0.23);
  @include transition;

  &:hover {
    transform: scale(1.025);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
    0 10px 10px rgba(0, 0, 0, 0.22);
  }
}