.card {
  width: 100%;
  padding: 20px;
  background-color: $color-bg-white;
  box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, .4);

  &:not(:last-child) {
    box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, .4),
    0 2px 2px 2px rgba(0, 0, 0, .4);

    @media (max-width: $screen-sm) {
      box-shadow: none;
    }
  }

  @media (max-width: $screen-sm) {
    box-shadow: none;
  }
}